import React, { useState, useEffect } from "react";
import { Card, CardImg, Button, Spinner, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { changePlaylistOrder, getPlaylist, changePlaylistShuffle } from "../services/playlist.service";

const Playlist = () => {
  const [playlist, setPlaylist] = useState([]);

  const changePlaylistOrderDown = (playlist_id, playlist_order, e) => {
    changePlaylistOrder(playlist_id, playlist_order, 0, 1);
    window.location.reload();
  };
  const changePlaylistOrderUp = (playlist_id, playlist_order, e) => {
    changePlaylistOrder(playlist_id, playlist_order, 0, 1);
    window.location.reload();
  };

  const changeShuffle = (playlist_id, should_shuffle, e) => {
    changePlaylistShuffle({ playlist_id: playlist_id, shuffle: should_shuffle });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  useEffect(() => {
    getPlaylist()
      .then((res) => {
        setPlaylist(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div>
      <div>
        <p
          style={{
            fontSize: "30px",
            fontWeight: "600",
            margin: "1rem 2.5rem",
          }}
        >
          Playlists
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          margin: "1.5rem 2%",
        }}
      >
        {playlist.length > 0 ? (
          playlist.map((genre) => {
            const path = "/playlist/" + genre.name + "/" + genre.id;
            return (
              <Card
                key={genre.id}
                style={{
                  height: "48rem",
                  margin: "0 1rem 2rem",
                  padding: "1rem",
                  backgroundColor: "#191a19",
                }}
              >
                <CardImg
                  alt="Card image cap"
                  src={genre.image_url}
                  top
                  style={{ width: "250px", height: "350px" }}
                />
                <div style={{ margin: "0.5rem 0" }}>
                  <h4 style={{ color: "#eb6eec" }}>{genre.name}</h4>
                  <Link to={path} state={{ playlist: genre }}>
                    <Button outline style={{ border: "solid 1px #eb6eec" }}>
                      Manage
                    </Button>
                  </Link>
                  &emsp;
                  <Input
                    type="checkbox"
                    checked={genre.should_shuffle}
                    onChange={changeShuffle.bind(this, genre.id, genre.should_shuffle)}
                  />{" "}
                  <span style={{ marginLeft: "0.5rem", color: "white" }}>Shuffle</span>
                  <h5 style={{ color: "white" }}>
                    Order: &emsp;
                    <button
                      style={{ backgroundColor: "#eb6eec" }}
                      onClick={changePlaylistOrderDown.bind(this, genre.id, genre.order - 1)}
                    >
                      -
                    </button>
                    &emsp; {genre.order} &emsp;
                    <button
                      style={{ backgroundColor: "#eb6eec" }}
                      onClick={changePlaylistOrderUp.bind(this, genre.id, genre.order + 1)}
                    >
                      +
                    </button>
                  </h5>
                </div>
              </Card>
            );
          })
        ) : (
          <Spinner color="warning">Loading....</Spinner>
        )}
      </div>
    </div>
  );
};

export default Playlist;
