import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "./Navbar";
import UploadForm from "./UploadForm";
import Videos from "./Videos";
import Playlist from "./Playlist";
import ManagePlaylist from "./ManagePlaylist";
import { getCurrentUser } from "../services/auth.service";
import config from "../config";
import Compositions from "./Composition";
import FeaturedVideo from "./FeaturedVideo";
import Categories from "./Category";
import ManageCategory from "./ManageCategory";
import Reporting from "./Reporting";
import Slurpy from "./Slurpy";

const MainPortal = () => {
  const IsAdmin = ({ children }) => {
    const currentUser = getCurrentUser();
    return currentUser.user.name === config.ADMIN || currentUser.user.name === config.SEC_ADMIN ? (
      children
    ) : (
      <Navigate to="/addvideo" replace />
    );
  };
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/addvideo" element={<UploadForm />} />
        <Route path="/allvideos" element={<Videos />} />
        <Route path="/reporting" element={<Reporting />} />
        <Route
          path="/featuredvideos"
          element={
            <IsAdmin>
              <FeaturedVideo />
            </IsAdmin>
          }
        />
        <Route
          path="/allcompositions"
          element={
            <IsAdmin>
              <Compositions />
            </IsAdmin>
          }
        />
        <Route
          path="/allcategories"
          element={
            <IsAdmin>
              <Categories />
            </IsAdmin>
          }
        />

        <Route
          path="/playlist"
          element={
            <IsAdmin>
              <Playlist />
            </IsAdmin>
          }
        />
        <Route
          path="/playlist/*"
          element={
            <IsAdmin>
              <ManagePlaylist />
            </IsAdmin>
          }
        />
        <Route
          path="/category/*"
          element={
            <IsAdmin>
              <ManageCategory />
            </IsAdmin>
          }
        />
        <Route
          path="/slurpy"
          element={
            <IsAdmin>
              <Slurpy />
            </IsAdmin>
          }
        />
      </Routes>
    </div>
  );
};

export default MainPortal;
