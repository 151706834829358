import React, { useState } from "react";
import { Card, CardImg, CardBody, Button, Form, FormGroup, Input, Label, Alert } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { login } from "../services/auth.service";

import logo from "../images/JUAN-STREAM-FULL-LOGO.png";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const handleLogin = (e) => {
    e.preventDefault();
    login(username, password).then((data) => {
      if (data.token) {
        navigate("/addvideo");
      } else {
        setMessage(data.msg);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      }
    });
  };
  return (
    <div>
      <Card
        style={{
          width: "20rem",
          margin: "2rem auto",
        }}
      >
        <CardImg alt="image" src={logo} top width="100%" />
        <CardBody>
          {message && <Alert color="danger" fade={false}>{message}</Alert>}
          <Form inline onSubmit={handleLogin}>
            <FormGroup floating>
              <Input
                id="username"
                name="username"
                placeholder="Username"
                type="text"
                onChange={onChangeUsername}
                required
              />
              <Label for="username">Username</Label>
            </FormGroup>{" "}
            <FormGroup floating>
              <Input
                id="password"
                name="password"
                placeholder="Password"
                type="password"
                onChange={onChangePassword}
                required
              />
              <Label for="password">Password</Label>
            </FormGroup>{" "}
            <Button
              style={{
                width: "10rem",
                background: "#000",
                color: "white",
                border: "none",
                fontWeight: "700",
                fontSize: "20px",
                transform: "translate(40%,30%)",
                marginBottom: "2rem",
              }}
            >
              Login
            </Button>
          </Form>
        </CardBody>
      </Card>
      <div
        style={{margin: "0 auto", width: "480px"}}>
        <iframe src="https://embeds.beehiiv.com/0c411b55-4f88-4a2c-abfc-dd2a1e7c2ac7" data-test-id="beehiiv-embed" width="480" height="320" frameborder="0" scrolling="no" style={{ borderRadius: "4px", border: "2px solid #e5e7eb", margin: "0", backgroundColor: "transparent" }}></iframe>
      </div>
    </div>
    
  );
};

export default Login;
