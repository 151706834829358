import axios from "axios";
import authHeader from "./auth-header";
import config from "../config";

export const getSinglePlaylist = (id) => {
  return axios.get(config.BASE_URL + "/playlist/single/" + id, {
    headers: authHeader(),
  });
};

export const changePlaylistOrder = (playlist_id, playlist_order, pre_id, next_id) => {
  return axios.post(
    config.BASE_URL + `/playlist/${playlist_id}/${playlist_order}/${pre_id}/${next_id}`,
    {},
    {
      headers: authHeader(),
    }
  );
};

export const getPlaylist = () => {
  return axios.get(config.BASE_URL + "/playlist", { headers: authHeader() });
};

export const getPlaylistVideos = (playlist_id) => {
  return axios.get(config.BASE_URL + `/playlist/${playlist_id}`, {
    headers: authHeader(),
  });
};

export const addToPlaylist = (video_id, playlist_id) => {
  return axios.post(
    config.BASE_URL + `/playlist/${video_id}/${playlist_id}`,
    {},
    {
      headers: authHeader(),
    }
  );
};

export const removeFromPlaylist = (video_id, playlist_id) => {
  return axios.delete(config.BASE_URL + `/playlist/${video_id}/${playlist_id}`, {
    headers: authHeader(),
  });
};

export const changeVideoOrder = (video_id, playlist_id, video_order) => {
  return axios.post(
    config.BASE_URL + `/playlist/${video_id}/${playlist_id}/${video_order}`,
    {},
    {
      headers: authHeader(),
    }
  );
};

export const changePlaylistShuffle = (data) => {
  return axios.post(config.BASE_URL + `/playlist/shuffle`, data, {
      headers: authHeader(),
    });
};
