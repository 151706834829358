import React from "react";
import logo from "../images/JUANSTREAM_ICON_TRNSPRNT.png";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { getCurrentUser, logout } from "../services/auth.service";
import { Button } from "reactstrap";
import config from "../config";

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const IsAdmin = ({ children }) => {
    const user = getCurrentUser();
    return user.user.name === config.ADMIN || user.user.name === config.SEC_ADMIN? children : <></>;
  };
  return (
    <div
      className="navbar navbar-dark bg-dark"
      style={{
        height: "7rem",
        position: "sticky",
        top: "0",
        zIndex: "1",
        backgroundColor: "#191a19",
      }}
    >
      <img
        src={logo}
        height="auto"
        width="70"
        style={{ marginLeft: "5rem" }}
        className="d-inline-block align-top"
        alt=""
      />
      <div style={{ position: "fixed", marginTop: "-50px", marginLeft: "auto", right: "5rem" }}>
        <Link to="/addvideo" className="navlink btn">
          Add Music Video
        </Link>
        <Link to="/allvideos" className="navlink btn">
          View All Videos
        </Link>
        <IsAdmin>
          <Link to="/reporting" className="navlink btn">
            Report
          </Link>
          <Link to="/featuredvideos" className="navlink btn">
            View Featured Videos
          </Link>
        </IsAdmin>
        <IsAdmin>
          <Link to="/allcompositions" className="navlink btn">
            View Compositions
          </Link>
        </IsAdmin>
        <IsAdmin>
          <Link to="/allcategories" className="navlink btn">
            View Categories
          </Link>
        </IsAdmin>
        <IsAdmin>
          <Link to="/slurpy" className="navlink btn">
            Slurpy
          </Link>
        </IsAdmin>
        <IsAdmin>
          <Link to="/playlist" className="navlink btn">
            Playlist
          </Link>
        </IsAdmin>
        <Button
          onClick={handleLogout}
          outline
          className="navlink"
          style={{ border: "solid 1px #fed53b" }}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Navbar;
