import axios from "axios";
import authHeader from "./auth-header";
import config from "../config";

export const getVideos = () => axios.get(config.BASE_URL + "/video", { headers: authHeader() });

export const deleteVideo = (id) =>
  axios.delete(config.BASE_URL + `/video/${id}`, {
    headers: authHeader(),
  });

export const saveMusicVideo = (data) => {
  return axios.post(config.BASE_URL + "/video", data, {
    headers: authHeader(),
  });
};

export const changeVideosUrls = (data) => {
  return axios.post(config.BASE_URL + "/video/change", data, {
    headers: authHeader(),
  });
};

export const changeVideoActive = (data) => {
  return axios.post(config.BASE_URL + `/video/update`, data,  {
    headers: authHeader(),
  });
};

export const changeVideoHide = (data) => {
  return axios.post(config.BASE_URL + `/video/hide`, data, {
    headers: authHeader(),
  });
};

export const changeVideoShare = (data) => {
  return axios.post(config.BASE_URL + `/video/share`, data, {
    headers: authHeader(),
  });
};

export const saveMp4 = (data) => {
  return axios.post(config.BASE_URL + `/video/makemp4`, data, {
    headers: authHeader(),
  });
};