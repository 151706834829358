import React, { useState, useEffect } from "react";
import { Table, Spinner, Label, Input, Button } from "reactstrap";
import exportFromJSON from "export-from-json";
import "./Thumbnail.css";
import "../icons.css";
import { getAllVideosReport, getReportByMonth, getReportByDay } from "../services/reporting.service";

const Reporting = () => {
  const [report, setReport] = useState([]);
  const [months, setMonths] = useState([]);
  const [days, setDays] = useState([]);
  const [typeOfDate, setTypeOfDate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null)
  const [title, setTitle] = useState("Report with no parameters");
  const [isDwDis, setIsDwDis] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === "radio1") {
      setTypeOfDate(e.target.value);
    }
    setIsDwDis(true)
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    setIsDwDis(true);
  };

  const handleDayChange = (e) => {
    setSelectedDay(e.target.value);
    setIsDwDis(true);
  };

  const getReport = (e) => {
    if (typeOfDate === "month" && selectedMonth != null) {
      getReportByMonth(selectedMonth)
        .then((res) => {
          setReport(res.data);
          setTitle("Report of " + selectedMonth);
          setIsDwDis(false);
          if (res.data.length < 1) {
            setIsDwDis(true);
          }
        })
        .catch((err) => console.log(err));
    }
    else if (typeOfDate === "day" && selectedDay != null) {
      getReportByDay(selectedDay.split(' ')[0], selectedDay.split(' ')[1])
        .then((res) => {
          setReport(res.data.day);
          setTitle("Report of " + selectedDay);
          setIsDwDis(false);
          if (res.data.length < 1) {
            setIsDwDis(true);
          }
          console.log(res.data.day)
        })
        .catch((err) => console.log(err));
    }
  };
  
  const saveReport = () => {
    let data = [];
    data = report;
    let fileName = "gogopix_report_";
    const exportType = exportFromJSON.types.csv;

    if (typeOfDate === "month" && selectedMonth != null) {
      fileName += `${selectedMonth}`;
      exportFromJSON({ data, fileName, exportType });
    } else if (typeOfDate === "day" && selectedDay != null) {
      fileName += `${selectedDay.split(" ")[0]}_${selectedDay.split(" ")[1]}`;
      exportFromJSON({ data, fileName, exportType });
    }
    else {
      fileName += 'total';
      exportFromJSON({ data, fileName, exportType });
    }
  };
  
  let ShowYday = ({ children }) => {
    return typeOfDate === "day" ? children : <></>;
  };

  useEffect(() => {
    getAllVideosReport()
      .then((res) => {
        setReport(res.data);
        if (res.data.length < 1) {
          setIsDwDis(true);
        }
      })
      .catch((err) => console.log(err));

    let today = new Date();
    const m = [];
    for (let i = 0; i < 6; i++) {
      today.setMonth(today.getMonth() - 1);
      m.push({ name: today.toLocaleString("default", { month: "long" }), num: today.getMonth() });
    }
    setMonths(m);

    const date = new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" });
    let today_2 = new Date(date.split(',')[0]);
    const d = [];
    d.push({
      month: today_2.toLocaleString("default", { month: "long" }),
      day: today_2.toISOString().split('T')[0].split('-')[2]
    });
      today_2.setDate(today_2.getDate() - 1);
    d.push({
      month: today_2.toLocaleString("default", { month: "long" }),
      day: today_2.toISOString().split("T")[0].split("-")[2],
    });
    setDays(d);

  }, []);

  return (
    <div style={{ margin: "2rem" }}>
      <div>
        <Label check>
          <Input
            type="radio"
            id="is"
            name="radio1"
            value="month"
            // defaultChecked
            onChange={handleChange}
          />{" "}
          Enter a month:{" "}
        </Label>{" "}
        <select onChange={handleMonthChange.bind(this)}>
          <option disabled selected value>
            {" "}
            -- select a month --{" "}
          </option>
          {months.map((record) => {
            return <option value={record.name}> {record.name} </option>;
          })}
        </select>{" "}
        <Label check>
          <Input type="radio" id="isDay" name="radio1" value="day" onChange={handleChange} /> Enter
          a day:
        </Label>{" "}
        <select onChange={handleDayChange.bind(this)}>
          <option disabled selected value>
            {" "}
            -- select a day --{" "}
          </option>
          {days.map((record) => {
            return (
              <option value={record.month + " " + record.day}>
                {" "}
                {record.month} {record.day}{" "}
              </option>
            );
          })}
        </select>{" "}
        <Button onClick={getReport.bind()}>Get report</Button>{" "}
        <Button disabled={isDwDis} onClick={saveReport}>
          Download report
        </Button>
      </div>
      <div>
        <p
          style={{
            fontSize: "25px",
            fontWeight: "600",
          }}
        >
          {title}
        </p>
      </div>
      <div style={{ width: "100%" }}>
        {report.length > 0 ? (
          <Table responsive striped style={{ background: "white", tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th style={{ width: "70px" }}>Video ID</th>
                <th style={{ width: "200px" }}>Title</th>
                <th style={{ width: "200px" }}>Artist</th>
                <th style={{ width: "200px" }}>Record Label </th>
                <th style={{ width: "100px" }}>ISRC</th>
                <th style={{ width: "100px" }}>Partner account</th>
                <th style={{ width: "70px" }}>Num of views</th>
                <ShowYday>
                  <th style={{ width: "70px" }}>Num of views (yesterday)</th>
                </ShowYday>
              </tr>
            </thead>
            <tbody>
              {report.map((record, idx) => {
                return (
                  <tr key={record.video_id}>
                    <th scope="row">{record.video_id}</th>
                    <td>{record.title}</td>
                    <td>{record.artist}</td>
                    <td>{record.record_label}</td>
                    <td>{record.isrc}</td>
                    <td>{record.partner_account}</td>
                    <td>{record.views}</td>
                    <ShowYday>
                      <td>{record.yday_views}</td>
                    </ShowYday>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
            <div className="no_playlist_msg">
              <h4>No report found</h4>
            </div>
        )}
      </div>
    </div>
  );
};

export default Reporting;
