import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";

import MainPortal from "./components/MainPortal";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import { getCurrentUser } from "./services/auth.service";

class App extends React.Component {
  render() {
    const RequireAuth = ({ children }) => {
      const currentUser = getCurrentUser();
      return currentUser.token ? children : <Navigate to="/" replace />;
    };

    const IsLoggedIn = ({ children }) => {
      const currentUser = getCurrentUser();
      return currentUser ? <Navigate to="/addvideo" replace /> : children;
    };
    return (
      <div>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <IsLoggedIn>
                <Login />
              </IsLoggedIn>
            }
          />
          <Route
            exact
            path="/signup"
            element={<SignUp />}
          />
          <Route
            path="/*"
            element={
              <RequireAuth>
                <MainPortal />
              </RequireAuth>
            }
          />
        </Routes>
      </div>
    );
  }
}

export default App;
