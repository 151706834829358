import React, { useState, useEffect } from "react";
import { Table, Spinner, Button, Modal, ModalBody } from "reactstrap";
import "./Playlist.css";
import "../icons.css";
import {
  getXmlFiles,
  performDdex,
  performSymphonic,
  performDpm,
  updateXml,
} from "../services/ddex.service";

const Slurpy = (props) => {
  const [processedXmls, setProcessedXmls] = useState([]);
  const [xmls, setXmls] = useState([]);
  const [modal, setModal] = useState({ isOpen: false, id: null, xml_name: null });
  
  const runDDEX = (xml_name, path, bucket_name, xml_id, e) => {
    const record = [];
    setXmls(
      xmls.filter((ele) => {
        if (ele.id === xml_id) {
          record.push(ele);
        }
        return ele.id !== xml_id;
      })
    );
    record[0].is_loading = true;
    setXmls([...xmls]);
    performDdex({ xml: xml_name, file_path: path, bucket_name: bucket_name }).then((res) => {
      console.log(res.data)
      if (res.data.msg.includes("Ingest was a success")) {
        updateXml({ id: xml_id, error: null });
        const record = [];
        setXmls(
          xmls.filter((ele) => {
            if (ele.id === xml_id) {
              record.push(ele);
            }
            return ele.id !== xml_id;
          })
        );
        setProcessedXmls([record[0], ...processedXmls]);
      } else if (res.data.msg.includes("Video file wasn't found")) {
        updateXml({ id: xml_id, error: "processing error" });
        const record = [];
        setXmls(
          xmls.filter((ele) => {
            if (ele.id === xml_id) {
              record.push(ele);
            }
            return ele.id !== xml_id;
          })
        );
        record[0].error_message = "processing error";
        setProcessedXmls([record[0], ...processedXmls]);
      } else if (res.data.msg.includes("xml file has more than one video")) {
        updateXml({ id: xml_id, error: res.data.msg });
        const record = [];
        setXmls(
          xmls.filter((ele) => {
            if (ele.id === xml_id) {
              record.push(ele);
            }
            return ele.id !== xml_id;
          })
        );
        record[0].error_message = res.data.msg;
        setProcessedXmls([record[0], ...processedXmls]);
      } else {
        const record = [];
        setXmls(
          xmls.filter((ele) => {
            if (ele.id === xml_id) {
              record.push(ele);
            }
            return ele.id !== xml_id;
          })
        );
        record[0].error_message = res.data.msg;
        record[0].is_loading = false;
        setXmls([...xmls]);
      }
    });
  };

  const runSym = (xml_name, path, bucket_name, xml_id, e) => {
    const record = [];
    setXmls(
      xmls.filter((ele) => {
        if (ele.id === xml_id) {
          record.push(ele);
        }
        return ele.id !== xml_id;
      })
    );
    record[0].is_loading = true;
    setXmls([...xmls]);
    performSymphonic({ xml: xml_name, file_path: path, bucket_name: bucket_name }).then((res) => {
      console.log(res.data);
      if (res.data.msg.includes("Ingest was a success")) {
        updateXml({ id: xml_id, error: null });
        const record = [];
        setXmls(
          xmls.filter((ele) => {
            if (ele.id === xml_id) {
              record.push(ele);
            }
            return ele.id !== xml_id;
          })
        );
        setProcessedXmls([record[0], ...processedXmls]);
      } else if (res.data.msg.includes("Video file wasn't found")) {
        updateXml({ id: xml_id, error: "processing error" });
        const record = [];
        setXmls(
          xmls.filter((ele) => {
            if (ele.id === xml_id) {
              record.push(ele);
            }
            return ele.id !== xml_id;
          })
        );
        record[0].error_message = "processing error";
        setProcessedXmls([record[0], ...processedXmls]);
      } else if (res.data.msg.includes("xml file has more than one video")) {
        updateXml({ id: xml_id, error: res.data.msg });
        const record = [];
        setXmls(
          xmls.filter((ele) => {
            if (ele.id === xml_id) {
              record.push(ele);
            }
            return ele.id !== xml_id;
          })
        );
        record[0].error_message = res.data.msg;
        setProcessedXmls([record[0], ...processedXmls]);
      } else {
        const record = [];
        setXmls(
          xmls.filter((ele) => {
            if (ele.id === xml_id) {
              record.push(ele);
            }
            return ele.id !== xml_id;
          })
        );
        record[0].error_message = res.data.msg;
        record[0].is_loading = false;
        setXmls([...xmls]);
      }
    });
  };

  const runDpm = (xml_name, path, bucket_name, xml_id, e) => {
    const record = [];
    setXmls(
      xmls.filter((ele) => {
        if (ele.id === xml_id) {
          record.push(ele);
        }
        return ele.id !== xml_id;
      })
    );
    record[0].is_loading = true;
    setXmls([...xmls]);
    performDpm({ xml: xml_name, file_path: path, bucket_name: bucket_name }).then((res) => {
      console.log(res.data)
      if (res.data.msg.includes("Ingest was a success")) {
        updateXml({ id: xml_id, error: null });
        const record = [];
        setXmls(
          xmls.filter((ele) => {
            if (ele.id === xml_id) {
              record.push(ele);
            }
            return ele.id !== xml_id;
          })
        );
        setProcessedXmls([record[0], ...processedXmls]);
      } else if (res.data.msg.includes("Video file wasn't found")) {
        updateXml({ id: xml_id, error: "processing error" });
        const record = [];
        setXmls(
          xmls.filter((ele) => {
            if (ele.id === xml_id) {
              record.push(ele);
            }
            return ele.id !== xml_id;
          })
        );
        record[0].error_message = "processing error";
        setProcessedXmls([record[0], ...processedXmls]);
      } else if (res.data.msg.includes("xml file has more than one video")) {
        updateXml({ id: xml_id, error: res.data.msg });
        const record = [];
        setXmls(
          xmls.filter((ele) => {
            if (ele.id === xml_id) {
              record.push(ele);
            }
            return ele.id !== xml_id;
          })
        );
        record[0].error_message = res.data.msg;
        setProcessedXmls([record[0], ...processedXmls]);
      } else {
        const record = [];
        setXmls(
          xmls.filter((ele) => {
            if (ele.id === xml_id) {
              record.push(ele);
            }
            return ele.id !== xml_id;
          })
        );
        record[0].error_message = res.data.msg;
        record[0].is_loading = false;
        setXmls([...xmls]);
      }
    });
  };

  const toggleModal = (id, name, e) => {
    setModal({ isOpen: !modal.isOpen, id: id, xml_name: name });
  };

  const updateXmlStatus = (id, e) => {
    updateXml({ id: id, error: null });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  useEffect(() => {
    getXmlFiles()
      .then((res) => {
        setXmls(
          res.data.filter((ele) => {
            return ele.is_done !== true;
          })
        );
        setProcessedXmls(
          res.data.filter((ele) => {
            return ele.is_done === true;
          })
        );
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div style={{ margin: "1rem 2rem" }}>
      <div>
        <p
          style={{
            fontSize: "25px",
            fontWeight: "600",
            marginLeft: "1.5rem",
          }}
        >
          xmls
        </p>
      </div>
      <div>
        {xmls ? (
          <div>
            <div className="table-container">
              {xmls.length > 0 ? (
                <Table responsive striped style={{ background: "white", tableLayout: "fixed" }}>
                  <thead>
                    <tr>
                      <th style={{ width: "100px" }}>Name</th>
                      <th style={{ width: "200px" }}>Path</th>
                      <th style={{ width: "100px" }}>Bucket</th>
                      <th style={{ width: "100px" }}>Artist</th>
                      <th style={{ width: "100px" }}>Title</th>
                      <th style={{ width: "70px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {xmls.map((record) => {
                      return (
                        <tr key={record.id}>
                          <td>{record.xml_name}</td>
                          <td>{record.file_path}</td>
                          <td>{record.bucket_name}</td>
                          <td>{record.artist}</td>
                          <td>{record.title}</td>
                          <td>
                            <div>
                              {record.is_loading ? (
                                <Spinner type="grow" />
                              ) : record.file_path.includes("symphonic") ? (
                                <><Button
                                  onClick={runSym.bind(
                                    this,
                                    record.xml_name,
                                    record.file_path,
                                    record.bucket_name,
                                    record.id
                                  )}>
                                  Run</Button>
                                  {"  "}
                                <Button onClick={toggleModal.bind(this, record.id, record.xml_name)}>Junk</Button></>
                              ) : record.file_path.includes("dpm") ? (
                                <><Button
                                  onClick={runDpm.bind(
                                    this,
                                    record.xml_name,
                                    record.file_path,
                                    record.bucket_name,
                                    record.id
                                  )}>
                                  Run</Button>
                                  {"  "}
                                <Button onClick={toggleModal.bind(this, record.id, record.xml_name)}>Junk</Button></>
                              ) : (
                                <><Button
                                  onClick={runDDEX.bind(
                                    this,
                                    record.xml_name,
                                    record.file_path,
                                    record.bucket_name,
                                    record.id
                                  )}
                                >
                                  Run
                                </Button>
                                  {"  "}
                                <Button onClick={toggleModal.bind(this, record.id, record.xml_name)}>Junk</Button></>
                              )}
                              {record.error_message ? (
                                <>
                                  <br />
                                  <label>{record.error_message}... Please try again</label>
                                </>
                              ) : (
                                <label></label>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <div className="no_playlist_msg">
                  <h4>No xmls to processed </h4>
                </div>
              )}
            </div>
            <p
              style={{
                fontSize: "25px",
                fontWeight: "600",
                marginLeft: "1.5rem",
              }}
            >
              Done xmls
            </p>
            <div className="table-container">
              <Table responsive striped style={{ background: "white", tableLayout: "fixed" }}>
                <thead>
                  <tr>
                    <th style={{ width: "100px" }}>Name</th>
                    <th style={{ width: "200px" }}>Path</th>
                    <th style={{ width: "100px" }}>Bucket</th>
                    <th style={{ width: "100px" }}>Artist</th>
                    <th style={{ width: "100px" }}>Title</th>
                    <th style={{ width: "150px" }}>Error</th>
                  </tr>
                </thead>
                <tbody>
                  {processedXmls.map((record) => {
                    return (
                      <tr key={record.id}>
                        <td>{record.xml_name}</td>
                        <td>{record.file_path}</td>
                        <td>{record.bucket_name}</td>
                        <td>{record.artist}</td>
                        <td>{record.title}</td>
                        <td>{record.error_message}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        ) : (
          <Spinner color="warning">Loading....</Spinner>
        )}
      </div>
      <Modal
        centered
        toggle={function noRefCheck() {}}
        isOpen={modal.isOpen}
        style={{ textAlign: "center" }}
        fade={false}
      >
        <ModalBody style={{ padding: "4rem" }}>
          Are You Sure, you want to marked xml titled "<span>{modal.xml_name}</span>" as done?
          <br />
          <div
            className="btn btn-warning mb-3 mt-3"
            style={{ width: "5rem" }}
            onClick={updateXmlStatus.bind(this, modal.id)}
          >
            Yes
          </div>
          {"   "}
          <div
            className="btn btn-outline-warning mt-3 mb-3"
            style={{ width: "5rem", marginLeft: "1rem" }}
            onClick={toggleModal}
          >
            Cancel
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Slurpy;
